<template>
    <div class="socials">
        <a href="https://github.com/ramona-ehlert/" target="_blank">
          <img src="../assets/github.svg" alt="the github logo" />
        </a>
        <a href="https://docs.google.com/document/d/1dc7_Jw78AP2YTyyirLJsgrKveBM4BeONyKJmgt8TxEw/edit?usp=sharing" target="_blank">
          <img src="../assets/resume.svg" alt="a resume logo" />
        </a>
        <a href="mailto:ramona.leigh.ehlert@gmail.com" target="_blank">
          <img src="../assets/email.svg" alt="an email logo" />
        </a>
        <a href="https://www.linkedin.com/in/ramona-ehlert/" target="_blank">
          <img src="../assets/linkedin.svg" alt="the linkedIn logo" />
        </a>
      </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
#footer{
  
 .socials {
     margin-top: 20px;
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr;
     justify-items: right;
     width: 280px;
      img {
    
        height: 30px;
    }
  }
}
</style>